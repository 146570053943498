<template>
    <base-btn-facebook
        :loading="loading"
        @click="onClick()"
    />
</template>

<script setup>
import { defineProps, defineEmits, onUnmounted } from 'vue';
import { useHead } from '@unhead/vue';
import { SOCIAL } from '../utils/constants';
import { useCurrentInstance } from '../composables/useCurrentInstance';
import BaseBtnFacebook from './BaseBtnFacebook.vue';

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['click', 'loginUser']);

const { $store } = useCurrentInstance();

const paramsApi = $store.state.params.api;
const facebookLang = (paramsApi.lang.iso || 'en_US').replace('-', '_');

const loginWithFacebook = () => {
    if (typeof FB !== 'undefined') {
        window.FB.login((response) => {
            if (response.status === 'connected') {
                const fToken = response.authResponse.accessToken;
                emit('loginUser', { fToken });
            }
        }, { scope: SOCIAL.SOCIAL_SCOPES[SOCIAL.SOCIAL_TYPES.FACEBOOK] });
    } else {
        setTimeout(loginWithFacebook, 2000);
    }
};

const onClick = () => {
    emit('click');
    loginWithFacebook();
};

useHead({
    script: [
        {
            key: 'facebookSdk',
            src: `https://connect.facebook.net/${facebookLang}/sdk.js`,
            onload: () => {
                window.fbAsyncInit = () => {
                    window.FB.init({
                        appId: paramsApi.facebook.appId,
                        cookie: false,
                        xfbml: false,
                        version: paramsApi.facebook.version,
                    });
                };
            },
        },
    ],
}, { mode: 'client' });

onUnmounted(() => {
    useHead({
        script: [{ key: 'facebookSdk' }],
    }, { mode: 'client' });
});
</script>
